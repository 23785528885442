.national-promo-view-component .filters {
  margin-bottom: 24px;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
}
.national-promo-view-component .filters .ui.icon.input {
  margin-right: 8px;
}
.national-promo-view-component .filters .ui.icon.input > i.icon.calendar + input {
  height: 41px;
}
.national-promo-view-component .promo-color {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}