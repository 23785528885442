.franchise-portal button.button.ui {
  border-radius: 0;
}
.franchise-portal button.button.ui.create-button {
  background: #E42338;
  color: #fff;
  padding: 17px 37px;
  font-size: 15px;
  font-family: "Interstate Light";
  text-transform: uppercase;
}
.franchise-portal button.button.ui.create-button .icon {
  opacity: 1;
}
.franchise-portal button.button.ui.create-button:hover {
  background: #FB273E;
}
.franchise-portal .calendar-section h4 {
  margin-bottom: 24px;
}
.franchise-portal .calendar-section .club-item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  font-size: 17px;
  line-height: 26px;
  color: #282828;
  font-family: "Interstate Light";
}
.franchise-portal .calendar-section .club-item.used {
  text-decoration: line-through;
}
.franchise-portal .calendar-section .calendars-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.franchise-portal .calendar-section .calendars-list .calendar-club-list {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  padding-right: 16px;
  margin-bottom: 16px;
}
.franchise-portal .calendar-section .calendars-list .calendar-club-list:last-child {
  padding-right: 0;
}
.franchise-portal .calendar-section .calendars-list .calendar-club-list h5 {
  padding: 12px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.franchise-portal .calendar-section .calendars-list .calendar-club-list h5 i:first-child {
  margin-left: 16px;
}
.franchise-portal .calendar-section .calendars-list .calendar-club-list h5 .splitter {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.franchise-portal .calendar-section .calendars-list .calendar-club-list .calendar-club-item {
  font-size: 17px;
  line-height: 26px;
  color: #282828;
  font-family: "Interstate Light";
}