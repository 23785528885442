.planned-promo-form .form-section {
  margin-bottom: 16px;
}
.planned-promo-form .platforms-plans {
  margin: 40px 0;
}
.planned-promo-form .with-overflow {
  overflow-x: auto;
  overflow-y: hidden;
}
.planned-promo-form .with-overflow > div:last-child {
  padding-bottom: 4px;
}
.planned-promo-form hr {
  border: none;
  border-top: 1px solid #dfdfdf;
  margin: 40px auto;
}
.planned-promo-form .ui.dropdown .text {
  font-family: "Interstate Light";
  font-size: 17px;
}
.planned-promo-form .ui.dropdown .text {
  font-family: "Interstate Light";
  font-size: 17px;
}
.planned-promo-form .ui.dropdown .item.disabled {
  padding-top: 0.45rem !important;
  padding-bottom: 0.45rem !important;
}
.planned-promo-form .ui.dropdown .item.disabled .text {
  font-size: 13px;
  font-family: "Interstate Regular";
  text-transform: uppercase;
}
.planned-promo-form .ui.dropdown.grouped-dropdown .item:not(.disabled) {
  padding-left: 2.28571428rem !important;
}
.planned-promo-form .ui.dropdown .menu .header {
  color: #676868;
  font-size: 13px;
  font-family: "Interstate Regular";
  margin: 0;
  padding: 16px;
  background: #fff;
  border-bottom: 1px solid #dfdfdf;
}
.planned-promo-form .ui.dropdown .menu .divider {
  border-top: 1px solid #dfdfdf;
}
.planned-promo-form .ui.dropdown .menu .item {
  color: #282828;
  font-size: 17px;
  font-family: "Interstate Light";
  text-wrap: wrap;
}
.planned-promo-form .lock-icon {
  color: #989898;
  padding-left: 4px;
}
.planned-promo-form .group-clubs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.planned-promo-form .group-clubs > div {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  padding-bottom: 8px;
}
.planned-promo-form .table .bordered {
  padding: 8px;
}
.planned-promo-form .table .price-group {
  padding: 0 24px;
  padding-top: 24px;
}
.planned-promo-form .table .price-group .form-group {
  margin-bottom: 0;
  margin-right: 0;
}
.planned-promo-form .table .price-group .promo-form-section {
  margin-top: 8px;
}
.planned-promo-form .table .pricing-block {
  max-width: 400px;
  border-right: 1px solid #dfdfdf;
}
.planned-promo-form .table .pricing-block:last-child {
  border-right: none;
}
.planned-promo-form .table .pricing-block .column-header {
  padding-left: 24px;
  text-align: center;
  font-size: 14px;
  min-width: 180px;
}
.planned-promo-form .table .table-header .checkbox.ui label {
  width: 100%;
  padding-left: 0;
}
.planned-promo-form .table .row-header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.planned-promo-form .dues-table {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.planned-promo-form .dues-table > div {
  border-right: 1px solid #DFDFDE;
  padding: 0 24px;
}
.planned-promo-form .dues-table > div:last-child {
  border: none;
  padding-right: 0;
}
.planned-promo-form .dues-table > div:first-child {
  padding-left: 0;
}
.planned-promo-form .promo-form-section-header {
  margin-bottom: 8px;
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.planned-promo-form .preview-pricing-block {
  color: #282828;
  font-family: "Interstate Light";
  font-size: 14px;
  line-height: 20px;
}
.planned-promo-form .preview-pricing-block .total {
  font-family: "Interstate Bold Condensed";
}
.planned-promo-form .drafts {
  margin-top: 24px;
}
.planned-promo-form .drafts p {
  font-size: 17px;
  line-height: 26px;
  color: #282828;
  font-family: "Interstate Light";
}
.planned-promo-form .drafts p.sub {
  font-size: 12px;
  line-height: 16px;
  color: #676868;
  margin-bottom: 8px;
}
.planned-promo-form .drafts .draft-examples.table-row > div {
  padding: 0 24px;
}
.planned-promo-form .drafts .draft-examples > div {
  font-size: 17px;
  line-height: 26px;
  color: #282828;
  font-family: "Interstate Light";
  border-right: 1px solid #DFDFDE;
}
.planned-promo-form .drafts .draft-examples > div:last-child {
  border-right: none;
  padding-right: 0;
}
.planned-promo-form .drafts .draft-examples > div:first-child {
  padding-left: 0;
}