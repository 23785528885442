.interface-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
}
.interface-header .splitter {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.interface-header h2 {
  color: #282828;
  font-family: "Interstate Bold Condensed";
  font-size: 40px;
  background: #F5F5F5;
}