.opt-out-component .error-block div.ui.message {
  margin-top: 20px;
}
.opt-out-component .opt-out-section {
  padding: 40px 0;
  border-bottom: 1px solid #DFDFDE;
}
.opt-out-component .opt-out-section:last-child {
  border-bottom: none;
}
.opt-out-component .group-clubs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.opt-out-component .group-clubs > div {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
}
.opt-out-component .group-clubs > div .calendar-club-item {
  padding-left: 1.85714em;
}