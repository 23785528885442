.day-preview .day-preview-header {
  background: #F5F5F5;
  padding: 20px 40px;
}
.day-preview .preview-pricing-block {
  color: #282828;
  font-family: "Interstate Light";
  font-size: 14px;
  line-height: 20px;
}
.day-preview .preview-pricing-block .total {
  font-family: "Interstate Bold Condensed";
}
.day-preview .preview-pricing-block .bordered {
  padding: 6px 12px;
}
.day-preview .preview-pricing-block .discounted {
  text-decoration: line-through;
}
.day-preview .splitter {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.day-preview .promo-description .form-group {
  margin-bottom: 0;
}
.day-preview .promo-color {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.day-preview .promo-subinfo {
  font-size: 14px;
  line-height: 16px;
  color: #282828;
  font-family: "Interstate Light";
}
.day-preview .promo-subinfo .promo-subinfo-header {
  color: #676868;
  font-size: 12px;
}
.day-preview .promo-subinfo > .table {
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid #DFDFDE;
}
.day-preview .promo-subinfo > .table:last-child {
  border-right: none;
}
.day-preview .subheader {
  font-family: "Interstate Bold Condensed";
  font-size: 17px;
  line-height: 26px;
  padding: 6px;
  background: #DFDFDE;
}