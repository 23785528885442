.promo-section-batch .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.promo-section-batch .col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.promo-section-batch .promo-header {
  font-family: "Interstate Bold";
  margin-right: 10px;
  width: 120px;
}
.promo-section-batch .update-status {
  text-align: right;
  margin-top: 10px;
}
.promo-section-batch .payment .preview-btn {
  margin-bottom: 10px;
}
.promo-section-batch .ui.table {
  font-size: 0.8em;
}
.promo-section-batch .success-wrapper {
  margin-top: 10px;
}