.login-screen h1 {
  margin: 40px 0;
  text-align: center;
}
.login-screen .form-group .ui.input {
  min-width: 400px;
}
.login-screen .button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}