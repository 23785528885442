.promo-preview-component .tabs-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #F5F5F5;
  padding-left: 40px;
}
.promo-preview-component .tabs-horizontal .interface-tab h4 {
  font-size: 20px;
  padding: 10px 32px;
}
.promo-preview-component .tabs-horizontal .interface-tab.active {
  border-bottom: 4px solid #FB273E;
}
.promo-preview-component .promo-subinfo {
  margin-bottom: 20px;
  color: #282828;
  font-size: 14px;
}
.promo-preview-component .promo-subinfo .promo-subinfo-header {
  color: #676868;
  font-size: 12px;
}
.promo-preview-component .platform-select {
  padding-top: 40px;
  padding-bottom: 24px;
  border-top: 1px solid #DFDFDE;
}
.promo-preview-component .plan-headers .tab {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.promo-preview-component .plan-headers .tab:first-child {
  background: #FBAC46;
  color: #000;
}