.message-component {
  position: fixed;
  top: 100px;
  left: 50%;
  padding: 12px 24px 12px 16px;
  border-radius: 32px;
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  font-family: "Interstate Light";
  background: #01875E;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.message-component .message-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.message-component i {
  font-size: 33px;
  height: auto;
}
.message-component.error {
  background: #E42338;
}