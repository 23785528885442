.side-menu .menu {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.side-menu .menu.minimized .name {
  display: none;
}
.side-menu .menu.ui.inverted.menu {
  border-radius: 0;
  background: #000;
  margin-bottom: 0;
}
.side-menu .menu.ui.inverted.menu .item {
  padding: 16px 19px;
}
.side-menu .menu .top-border {
  border-top: 1px solid #676868;
}
.side-menu .menu .item {
  border-left: 4px solid #282828;
  font-family: "Interstate Regular";
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #989898 !important;
}
.side-menu .menu .item.active {
  border-left: 4px solid #FB273E;
  background: #282828;
}
.side-menu .menu .minimiser-container {
  height: 32px;
}
.side-menu .menu .minimiser-container .minimiser {
  background: #FBAC46;
  border-radius: 8px 0 0 8px;
  padding: 8px 4px 8px 4px;
  position: absolute;
  right: 0;
  color: #282828;
  cursor: pointer;
}