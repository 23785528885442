.calendar-module .calendar-form > .form-group {
  margin-bottom: 40px;
}
.calendar-module .calendar-form h3 {
  margin-bottom: 24px;
  margin-top: 40px;
}
.calendar-module .calendar-form .form-group.group-clubs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.calendar-module .calendar-form .form-group.group-clubs > div {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  padding-bottom: 8px;
}
.calendar-module .calendar-form .checkbox {
  height: auto;
  margin-bottom: 8px;
}