.franchise-portal h1, .franchise-portal h2, .franchise-portal h3, .franchise-portal h4, .franchise-portal h5, .franchise-portal h6 {
  color: #282828;
  font-family: "Interstate Bold Condensed";
  line-height: 1.2;
}
.franchise-portal h1 {
  font-size: 40px;
}
.franchise-portal h2 {
  font-size: 40px;
}
.franchise-portal h3 {
  font-size: 26px;
}
.franchise-portal h4 {
  font-size: 26px;
}
.franchise-portal h5 {
  font-size: 20px;
}
.franchise-portal .body3 {
  font-size: 12px;
  font-family: "Interstate Regular";
  line-height: 16px;
}
.franchise-portal .white {
  color: #fff;
}
.franchise-portal .fc-event {
  height: 24px;
  line-height: 20px;
  font-size: 14px;
  padding-left: 8px;
}
.franchise-portal .fc-event .fc-event-main {
  color: #282828;
  font-family: "Interstate Light";
}
.franchise-portal .fc-day:hover {
  background-color: rgba(245, 245, 245, 0.5);
  -webkit-box-shadow: 0px 2px 5px 1px rgba(155, 155, 155, 0.5);
          box-shadow: 0px 2px 5px 1px rgba(155, 155, 155, 0.5);
}
.franchise-portal .fc-day-other {
  background-color: #F5F5F5;
}
.franchise-portal .fc-day-today {
  background-color: #FBAC46;
}
.franchise-portal .fc-col-header-cell-cushion {
  color: #282828;
  line-height: 16px;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Interstate Regular";
}
.franchise-portal .fc-daygrid-day-top {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.franchise-portal .fc-daygrid-day-top a {
  color: #282828;
  line-height: 16px;
  font-size: 13px;
}
.franchise-portal .calendar-tables .table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.franchise-portal .calendar-tables .table.table-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.franchise-portal .calendar-tables .table.wrap-row {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.franchise-portal .calendar-tables .table .table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.franchise-portal .calendar-tables .table .table-row.center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.franchise-portal .calendar-tables .table .splitter {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.franchise-portal .calendar-tables .table .table-cell {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.franchise-portal .calendar-tables .table .bordered {
  border: 1px solid #DFDFDE;
}
.franchise-portal.modal {
  padding-top: 0;
  margin-top: 0;
}
.franchise-portal.modal .header.without-margin {
  margin-bottom: 0;
  border-bottom: 0;
}
.franchise-portal.modal > .content {
  padding: 0;
}
.franchise-portal.modal > .content .padded {
  padding: 40px;
}
.franchise-portal.modal > .close {
  top: 1rem;
  right: 1rem;
  color: #282828;
}
.franchise-portal.modal .header {
  font-family: "Interstate Bold Condensed";
  font-size: 34px;
  line-height: 1.2;
  margin-bottom: 16px;
  padding: 20px 40px;
  background: #F5F5F5;
}
.franchise-portal.modal .header:after {
  display: none;
}
.franchise-portal.modal .modal-button-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 16px 40px;
  border-top: 1px solid #DFDFDE;
  position: -webkit-sticky;
  position: sticky;
  bottom: -16px;
  background: #fff;
  z-index: 20;
}
.franchise-portal.modal .ui.button {
  border: none;
  border-radius: 0;
  height: 48px;
  padding: 14px 32px 14px 32px;
  font-size: 15px;
  font-family: "Interstate Regular";
  color: #fff;
  background: #000;
  margin-right: 8px;
}
.franchise-portal.modal .ui.button:hover {
  background: #282828;
}
.franchise-portal.modal .ui.button:focus {
  outline: 2px solid #FBAC46;
}
.franchise-portal.modal .ui.button:last-child {
  margin-right: 0;
}
.franchise-portal.modal .ui.button:disabled {
  background: #DFDFDE;
  color: #989898;
}
.franchise-portal.modal .ui.button.secondary {
  color: #282828;
  border: 1px solid #000;
  background: none;
}
.franchise-portal.modal .ui.button.secondary:hover {
  background: #F5F5F5;
}
.franchise-portal.modal .ui.button.no-border {
  border: none;
}
@media screen and (min-width: 1200px) {
  .franchise-portal.modal {
    width: 90%;
  }
}
.franchise-portal .ui.button {
  border: none;
  border-radius: 0;
  height: 48px;
  padding: 14px 32px 14px 32px;
  font-size: 15px;
  font-family: "Interstate Regular";
  color: #fff;
  background: #000;
}
.franchise-portal .ui.button.icon {
  padding: 0;
  width: 48px;
}
.franchise-portal .ui.button:disabled {
  background: #DFDFDE;
  color: #989898;
}
.franchise-portal .ui.button.secondary {
  color: #282828;
  border: 1px solid #000;
  background: none;
}
.franchise-portal .ui.button.no-border {
  border: none;
}
.franchise-portal .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 24px;
  margin-right: 24px;
}
.franchise-portal .form-group.with-label {
  position: relative;
}
.franchise-portal .form-group.with-label > label {
  position: absolute;
  top: -6px;
  font-size: 12px;
  color: #676868;
  padding: 0 4px;
  left: 12px;
  z-index: 10;
  background: #fff;
  font-family: "Interstate Regular";
}
.franchise-portal .form-group:last-child {
  margin-right: 0;
}
.franchise-portal .form-group input {
  height: 48px;
  border-radius: 4px;
  border: 1px solid #989898;
  color: #282828;
}
.franchise-portal .form-group input::-webkit-input-placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group input::-moz-placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group input:-ms-input-placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group input::-ms-input-placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group input::placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group textarea {
  border-radius: 4px;
  border: 1px solid #989898;
  margin-top: 8px;
  color: #282828;
  padding: 10px 15px;
}
.franchise-portal .form-group textarea::-webkit-input-placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group textarea::-moz-placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group textarea:-ms-input-placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group textarea::-ms-input-placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group textarea::placeholder {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}
.franchise-portal .form-group .info {
  font-family: "Interstate Light";
  color: #676868;
  font-size: 12px;
  line-height: 16px;
}
.franchise-portal .form-group .ui.icon.input > i.icon.calendar {
  background: none;
  width: 48px;
  height: 48px;
}
.franchise-portal .form-group .ui.icon.input > i.icon.calendar:before {
  color: #282828;
}
.franchise-portal .checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 48px;
}
.franchise-portal .checkbox input ~ label {
  font-family: "Interstate Light";
  font-size: 17px;
  color: #282828;
}
.franchise-portal .checkbox input ~ label:before {
  border: 1px solid #282828;
  width: 20px;
  height: 20px;
}
.franchise-portal .checkbox input:checked + label:before {
  background: #E42338;
  border: 1px solid #E42338;
}
.franchise-portal .checkbox input:checked + label:after {
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.franchise-portal .checkbox.toggle.ui input ~ label {
  padding-left: 0;
  padding-right: 4.5rem;
}
.franchise-portal .checkbox.toggle.ui input ~ label:before {
  background: #989898;
  right: 0;
  left: auto;
}
.franchise-portal .checkbox.toggle.ui input ~ label:after {
  left: auto;
  right: 2.75em;
  -webkit-transition: background 0.3s ease 0s, right 0.3s ease 0s;
  transition: background 0.3s ease 0s, right 0.3s ease 0s;
  width: 1.5rem;
  height: 1.5rem;
}
.franchise-portal .checkbox.toggle.ui input:checked + label:before {
  background: #E42338 !important;
}
.franchise-portal .checkbox.toggle.ui input:checked + label:after {
  left: auto;
  right: 0;
}
.franchise-portal .checkbox.toggle.ui.left input ~ label {
  padding-left: 4.5rem;
  padding-right: 0;
}
.franchise-portal .checkbox.toggle.ui.left input ~ label:before {
  left: 0;
}
.franchise-portal .checkbox.toggle.ui.left input ~ label:after {
  left: 0;
}
.franchise-portal .checkbox.toggle.ui.left input:checked + label:before {
  background: #E42338 !important;
}
.franchise-portal .checkbox.toggle.ui.left input:checked + label:after {
  left: 2.75em;
}
.franchise-portal .checkbox.radio {
  font-size: 300px;
}
.franchise-portal .checkbox.radio input + label {
  line-height: 20px;
}
.franchise-portal .checkbox.radio input + label:before {
  border: 1px solid #282828;
  width: 20px;
  height: 20px;
}
.franchise-portal .checkbox.radio input:checked + label:before {
  border: 6px solid #E42338;
  background: none;
}
.franchise-portal .checkbox.radio input:checked + label:after {
  display: none;
}
.franchise-portal textarea {
  border: 1px solid #989898;
  border-radius: 4px;
}
.franchise-portal textarea:focus, .franchise-portal textarea:hover {
  border: 1px solid #989898;
}
.franchise-portal .tab {
  background: #282828;
  border-radius: 8px 8px 0 0;
  height: 36px;
  margin: 0 1px;
  padding: 8px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.franchise-portal .tab.empty {
  background: none;
}
.franchise-portal .tab .ui.checkbox {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}
.franchise-portal .tab .ui.checkbox label {
  font-family: "Interstate Bold Condensed";
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}
.franchise-portal .label-round-orange {
  color: #282828;
  font-size: 17px;
  line-height: 26px;
  font-family: "Interstate Regular";
  background: #FBAC46;
  padding: 3px 16px;
  border-radius: 100px;
  margin-top: 40px;
  margin-bottom: 16px;
  display: inline-block;
}
.franchise-portal .warning.icon {
  font-family: "Interstate Light";
}
.franchise-portal .warning.icon .info.icon {
  color: #FBAC46;
  font-size: 24px;
}
.light-body-17 {
  font-family: "Interstate Light";
  font-size: 17px;
  line-height: 26px;
  color: #282828;
}