.franchise-portal .full-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-height: calc(100vh - 65px);
}
.franchise-portal .full-content .side-menu {
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.franchise-portal .full-content .side-menu .separator {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.franchise-portal .full-content .side-menu .item {
  text-align: left !important;
}
.franchise-portal .full-content .side-menu .copyright {
  background: #000;
  color: #989898;
  font-size: 12px;
  line-height: 16px;
  font-family: "Interstate Light";
  padding: 16px 19px;
}
.franchise-portal .full-content .franchise-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 32px;
  background: #F5F5F5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}