.auth-layout-component {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: -webkit-gradient(linear, left top, left bottom, from(#FBAC46), to(#FB273E));
  background: linear-gradient(180deg, #FBAC46 0%, #FB273E 100%);
}
.auth-layout-component .form-wrapper {
  max-width: 528px;
  background: #FFFFFF;
  -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.3019607843);
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.3019607843);
  padding: 40px 64px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.auth-layout-component .form-wrapper .auth-header-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.auth-layout-component footer .body3 {
  margin-top: 80px;
}