.franchise-portal .top-bar {
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 24px;
}
.franchise-portal .top-bar .logo {
  width: 65px;
  margin: 12px 24px 12px 0;
}
.franchise-portal .top-bar h5 {
  font-size: 20px;
  font-family: "Interstate Regular";
  border-left: 1px solid #676868;
  color: #fff;
  text-transform: capitalize;
  padding-left: 24px;
}
.franchise-portal .top-bar .splitter {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.franchise-portal .profile-menu {
  margin-left: 16px;
  font-size: 14px;
  line-height: 16px;
  font-family: "Interstate Regular";
}