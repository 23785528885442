.calendar-view-component .calendar-toolbar {
  margin-bottom: 24px;
}
.calendar-view-component .calendar-toolbar .calendar-type-select .ui.button:disabled {
  background: #000;
  color: #fff;
  opacity: 1 !important;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-title {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-title h3 {
  line-height: 48px;
}
.calendar-view-component .calendar-toolbar .button-group {
  margin-right: 24px;
}
.calendar-view-component .calendar-toolbar .button-group.small-margin {
  margin-right: 8px;
}
.calendar-view-component .calendar-toolbar .button-group .ui.button {
  margin-right: 0;
}
.calendar-view-component .calendar-toolbar .button-group .ui.button i.icon {
  font-size: 18px;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-right .ui.dropdown {
  margin-right: 8px;
  min-width: 10em;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-right .ui.dropdown .text {
  font-family: "Interstate Light";
  font-size: 17px;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-right .ui.dropdown .item.disabled {
  padding-top: 0.45rem !important;
  padding-bottom: 0.45rem !important;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-right .ui.dropdown .item.disabled .text {
  font-size: 13px;
  font-family: "Interstate Regular";
  text-transform: uppercase;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-right .ui.dropdown.grouped-dropdown {
  min-width: 200px;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-right .ui.dropdown.grouped-dropdown .item:not(.disabled) {
  padding-left: 2.28571428rem !important;
}
.calendar-view-component .calendar-toolbar .calendar-toolbar-right .ui.dropdown:last-child {
  margin-right: 0;
}
.calendar-view-component .fc-event.national .fc-event-title:before {
  content: "\F04CE";
  opacity: 1;
  margin: 0 0.25rem 0 0;
  width: 1.18em;
  height: 1em;
  font-family: "Material Design Icons";
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  speak: none;
  display: inline-block;
}